import type { FC, MouseEvent } from 'react'
import { useState } from 'react'
import { Icon, Link, StackDivider, Text, VStack } from '@chakra-ui/react'
import { EmptyMessage, GrandHeading, GrandSection } from 'ui'
import { FetchError, fetchJson } from 'api'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider, overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { SignupFormType } from 'forms/forms/SignupForm'
import SignupForm from 'forms/forms/SignupForm'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useRouter } from 'next/navigation'
import { useUser } from '../../index'

interface SignupPageStdConfig {
  paths: {
    signupApiRoute: string
  }
  redirects: {
    onLoggedIn: string
    toNewPassword: string
  }
  overrides?: OverridesContextType
}

export interface SignupPageStdOverrides {
  registerAccount?: string
  accountRegistration?: string
  signUpSuccessMsg?: string
  signUpErrorMsgRecoverPassword?: string
  signupRecoverPasswordLink?: string
  signupInvalidCredentials?: string
}

interface SignupPageStdProps {
  config: SignupPageStdConfig
}

const SignupPageStd: FC<SignupPageStdProps> = ({ config }) => {
  const [errorMsg, setErrorMsg] = useState('')
  const overrides = useOverridesContext<keyof SignupPageStdOverrides>()
  const [successMsg, setSuccessMsg] = useState('')
  const router = useRouter()

  useUser({
    redirectTo: config.redirects.onLoggedIn,
    redirectIfFound: true
  })

  const handleSignup = async (data: Partial<SignupFormType>) => {
    try {
      await fetchJson(config.paths.signupApiRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          type: 'CONSUMER'
        })
      })
      setSuccessMsg(
        overrideText(
          'Kontot har registrerats, du kommer att få ett mail inom kort med instruktioner.',
          overrides?.signUpSuccessMsg
        )
      )
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(overrideText('Ogiltiga uppgifter', overrides?.signupInvalidCredentials))
        setSuccessMsg('')
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }
    }
  }

  const handleForgottenPassword = (e: MouseEvent) => {
    e.preventDefault()
    router.push(config.redirects.toNewPassword)
  }

  return (
    <OverridesContextProvider overrides={{ ...config.overrides }}>
      <GrandSection minH={800} sectionId="registrera">
        <VStack
          alignSelf="center"
          bg="background.surface"
          borderColor="primary"
          borderRadius="sm"
          borderWidth={1}
          divider={<StackDivider />}
          p={6}
          w={{
            base: 'full',
            md: 'lg'
          }}>
          <GrandHeading title={overrideText('Registrera', overrides?.registerAccount)} />
          {successMsg ? (
            <VStack p={4} spacing={6} w="full">
              <Icon as={AiFillCheckCircle} color="status.success" fontSize="6xl" />
              <EmptyMessage text={successMsg} textAlign="left" />
            </VStack>
          ) : (
            <VStack w="xs">
              <SignupForm onFormSubmit={handleSignup} />
              {errorMsg ? (
                <Text>
                  {overrideText(
                    'Din mailadress finns redan registrerad hos oss. Har du glömt ditt lösenord' +
                      ' klicka',
                    overrides?.signUpErrorMsgRecoverPassword
                  )}
                  <Link
                    color="primary"
                    flex={1}
                    id="registerAccount"
                    onClick={handleForgottenPassword}
                    textAlign="right">
                    {' '}
                    {overrideText('här', overrides?.signUpErrorMsgRecoverPassword)}
                  </Link>
                </Text>
              ) : null}
            </VStack>
          )}
        </VStack>
      </GrandSection>
    </OverridesContextProvider>
  )
}

export default SignupPageStd
