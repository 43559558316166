import type { NextApiRequest, NextApiResponse } from 'next'
import storefrontApi from 'api/storefront-api'
import type { CreateCustomerDTO, CredentialsDTO, CustomerLightDTO, Language } from 'ecosystem'
import { useLocaleCredentials as setLocaleCredentials } from 'api'
import type { User } from '../../utils'

interface SetPasswordParams {
  activationToken: string
  newPassword: string
}

export async function setPasswordHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  const { activationToken, newPassword }: SetPasswordParams = await req.body

  try {
    const customer = await storefrontApi.auth.setPassword({
      activationToken,
      newPassword,
      email: null,
      oldPassword: null
    })
    res.json(customer)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

export async function sessionRouteHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  const customer: CustomerLightDTO = await req.body

  try {
    const user: User = { isLoggedIn: true, customerId: customer.id, customer: null }
    req.session.user = user
    await req.session.save()
    res.json(user)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

interface ResetPasswordParams {
  email: string
  oldPassword: string
  newPassword: string
}

export async function resetPasswordHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (!req.session.user?.customerId) {
    res.status(401).json({ message: 'Unauthorized' })
    return
  }

  try {
    const { oldPassword, newPassword, email }: ResetPasswordParams = await req.body

    await storefrontApi.auth.resetPassword({
      oldPassword,
      newPassword,
      email,
      activationToken: null
    })
    res.json({ message: 'OK' })
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

interface ResendMagicLinkParams {
  email: string
}

export async function resendMaginLinkHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  const { email }: ResendMagicLinkParams = await req.body

  try {
    await storefrontApi.auth.resendMagicLink({
      email,
      activationToken: null,
      newPassword: null,
      oldPassword: null
    })
    res.json({ message: 'OK' })
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

export function logoutHandler(req: NextApiRequest, res: NextApiResponse<User>) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  req.session.destroy()
  res.json({ isLoggedIn: false, customer: null, customerId: null })
}

export async function loginHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  const { email, password }: CredentialsDTO = await req.body

  try {
    const customer = await storefrontApi.auth.login({
      email,
      password
    })
    res.json(customer)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

export async function userHandler(req: NextApiRequest, res: NextApiResponse<User>) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.session.user?.customerId) {
    const customer = await storefrontApi.customer.findById(req.session.user.customerId)

    res.json({
      ...req.session.user,
      customer,
      isLoggedIn: true
    })
  } else {
    res.json({
      isLoggedIn: false,
      customerId: null,
      customer: null
    })
  }
}

export async function signupHandler(req: NextApiRequest, res: NextApiResponse) {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  const { email, firstName, lastName, type }: CreateCustomerDTO = await req.body

  try {
    const customer = await storefrontApi.auth.signup({ email, firstName, lastName, type })
    res.json(customer)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}
