import type { FC, MouseEvent } from 'react'
import { useState } from 'react'
import { HStack, Link, StackDivider, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandSection, GrandText } from 'ui'
import type { CustomerLightDTO } from 'ecosystem'
import { FetchError, fetchJson } from 'api'
import { useRouter } from 'next/navigation'
import type { FormUpdatePayload, LoginFormType } from 'forms'
import { LoginForm } from 'forms'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider, overrideText } from 'ui/lib/overrides'
import { useUser } from '../../index'

interface LoginPageStdConfig {
  paths: {
    loginApiRoute: string
    sessionApiRoute: string
  }
  redirects: {
    onLoggedIn: string
    toNewPassword: string
    toSignup: string
  }
  overrides?: OverridesContextType
}

export interface LoginPageStdOverrides {
  loginPageTitle?: string
  loginForgottenPwd?: string
  loginForgottenPwdDesc?: string
  registerAccount?: string
}

interface LoginPageStdProps {
  config: LoginPageStdConfig
}

const LoginPageStd: FC<LoginPageStdProps> = ({ config }) => {
  const { mutateUser } = useUser({
    redirectTo: config.redirects.onLoggedIn,
    redirectIfFound: true
  })
  const router = useRouter()
  const overrides = useOverridesContext<keyof LoginPageStdOverrides>()

  const [errorMsg, setErrorMsg] = useState('')
  const [loginEmail, setLoginEmail] = useState('')

  const handleLogin = async (data: Partial<LoginFormType>) => {
    try {
      const customer: CustomerLightDTO = await fetchJson(config.paths.loginApiRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: data.email,
          password: data.password
        })
      })

      await mutateUser(
        await fetchJson(config.paths.sessionApiRoute, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(customer)
        })
      )
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(error.data.message)
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }
    }
  }

  const handleOnFormUpdate = (payload: FormUpdatePayload<LoginFormType>) => {
    if (payload.updatedData.email) {
      setLoginEmail(payload.updatedData.email)
    }
  }

  const handleForgottenPassword = (e: MouseEvent) => {
    e.preventDefault()

    if (loginEmail) {
      router.push(`${config.redirects.toNewPassword}?email=${loginEmail}`)
    } else {
      router.push(config.redirects.toNewPassword)
    }
  }

  const handleRegisterAccount = (e: MouseEvent) => {
    e.preventDefault()
    router.push(config.redirects.toSignup)
  }

  return (
    <OverridesContextProvider overrides={{ ...config.overrides }}>
      <GrandSection sectionId="login">
        <VStack
          alignSelf="center"
          bg="background.default"
          borderColor="primary"
          borderRadius="sm"
          borderWidth={1}
          divider={<StackDivider />}
          p={6}
          w={{
            base: 'full',
            md: 'lg'
          }}>
          <GrandHeading title={overrideText('Logga in', overrides?.loginPageTitle)} />
          <VStack spacing={6} w="300px">
            <LoginForm error={errorMsg} onFormSubmit={handleLogin} onUpdate={handleOnFormUpdate} />
            <HStack w="full">
              <Link color="primary" flex={1} id="forgottenPwd" onClick={handleForgottenPassword}>
                {overrideText('Glömt lösenord?', overrides?.loginForgottenPwd)}
              </Link>
              <Link
                color="primary"
                flex={1}
                id="registerAccount"
                onClick={handleRegisterAccount}
                textAlign="right">
                {overrideText('Registrera', overrides?.registerAccount)}
              </Link>
            </HStack>
          </VStack>
          <GrandText id="loginForgottenPwdDesc">
            {overrideText(
              `Ett konto skapas automatiskt
             åt dig när du lägger din första beställning och anvisningar
          skickas med din orderbekräftelse.`,
              overrides?.loginForgottenPwdDesc
            )}
          </GrandText>
        </VStack>
      </GrandSection>
    </OverridesContextProvider>
  )
}

export default LoginPageStd
