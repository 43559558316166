import type { FC } from 'react'
import { useState } from 'react'
import { Link, StackDivider, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandSection } from 'ui'
import type { MagicLinkFormType } from 'forms'
import { MagicLinkForm } from 'forms'
import { FetchError, fetchJson } from 'api'
import { useRouter } from 'next/navigation'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider, overrideText } from 'ui/lib/overrides'
import { UseSearchParamsSuspense } from 'ui/wrappers'
import { useUser } from '../../index'

interface NewPasswordPageConfig {
  paths: {
    resendMaginLinkApiRoute: string
  }
  redirects: {
    onLoggedIn: string
    toLogin: string
  }
  overrides?: OverridesContextType
}

export interface InterfaceNewPasswordPageStdOverrides {
  toLogin?: string
  requestPwd?: string
}

interface NewPasswordPageStdProps {
  config: NewPasswordPageConfig
}

const NewPasswordPageStd: FC<NewPasswordPageStdProps> = ({ config }) => {
  useUser({ redirectTo: config.redirects.onLoggedIn, redirectIfFound: true })
  const [errorMsg, setErrorMsg] = useState('')
  const router = useRouter()
  const overrides = useOverridesContext<keyof InterfaceNewPasswordPageStdOverrides>()

  const handleMagicLink = async (data: MagicLinkFormType) => {
    try {
      await fetchJson(config.paths.resendMaginLinkApiRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: data.email
        })
      })
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(error.data.message)
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }
    }
  }

  const handleToLogin = () => {
    router.push(config.redirects.toLogin)
  }
  return (
    <OverridesContextProvider overrides={{ ...config.overrides }}>
      <GrandSection sectionId="nytt-losenord">
        <VStack
          alignSelf="center"
          bg="background.default"
          borderColor="primary"
          borderRadius="sm"
          borderWidth={1}
          divider={<StackDivider />}
          p={6}
          w={{
            base: 'full',
            md: 'lg'
          }}>
          <GrandHeading title={overrideText('Begär nytt lösenord', overrides?.requestPwd)} />
          <VStack spacing={6} w="300px">
            <UseSearchParamsSuspense
              fallback={
                <MagicLinkForm
                  data={{ email: '' }}
                  error={errorMsg}
                  onFormSubmit={handleMagicLink}
                />
              }>
              {(searchParams) => (
                <MagicLinkForm
                  data={{ email: searchParams?.get('email') || '' }}
                  error={errorMsg}
                  onFormSubmit={handleMagicLink}
                />
              )}
            </UseSearchParamsSuspense>

            <Link alignSelf="flex-end" color="primary" id="toLogin" onClick={handleToLogin}>
              {overrideText(`Tillbaka till inloggningen`, overrides?.toLogin)}
            </Link>
          </VStack>
        </VStack>
      </GrandSection>
    </OverridesContextProvider>
  )
}

export default NewPasswordPageStd
