import { type FC, Suspense, useState } from 'react'
import { StackDivider, VStack } from '@chakra-ui/react'
import { EmptyMessage, GrandHeading, GrandSection, GrandText } from 'ui'
import type { ChangePasswordSubmitData } from 'forms'
import { ChangePasswordForm } from 'forms'
import { BiError } from 'react-icons/bi'
import type { CustomerLightDTO } from 'ecosystem'
import { FetchError, fetchJson } from 'api'
import { useSearchParams } from 'next/navigation'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider, overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useUser } from '../../index'

interface RegisterPageStdConfig {
  paths: {
    sessionApiRoute: string
    setPasswordApiRoute: string
  }
  redirects: {
    onLoggedIn: string
  }
  overrides?: OverridesContextType
}

export interface RegisterPageStdOverrides {
  accountRegistration?: string
  registerAccount?: string
}

interface RegisterPageStdProps {
  config: RegisterPageStdConfig
}

const RegisterPageStd: FC<RegisterPageStdProps> = ({ config }) => {
  const [errorMsg, setErrorMsg] = useState('')
  const overrides = useOverridesContext<keyof RegisterPageStdOverrides>()
  const searchParams = useSearchParams()

  const { mutateUser } = useUser({
    redirectTo: config.redirects.onLoggedIn,
    redirectIfFound: true
  })

  if (!searchParams?.get('token')) {
    return <EmptyMessage icon={<BiError size={80} />} text="Missing QS parameters" />
  }

  const handleAccountRegister = async (data: ChangePasswordSubmitData) => {
    try {
      const customer: CustomerLightDTO = await fetchJson(config.paths.setPasswordApiRoute, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          activationToken: searchParams?.get('token'),
          newPassword: data.password
        })
      })

      await mutateUser(
        await fetchJson(config.paths.sessionApiRoute, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(customer)
        })
      )
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(error.data.message)
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }
    }
  }

  return (
    <OverridesContextProvider overrides={{ ...config.overrides }}>
      <GrandSection minH={800} sectionId="registrera">
        <VStack
          alignSelf="center"
          bg="background.surface"
          borderColor="primary"
          borderRadius="sm"
          borderWidth={1}
          divider={<StackDivider />}
          p={6}
          w={{
            base: 'full',
            md: 'lg'
          }}>
          <GrandHeading title="Registrera" />
          <ChangePasswordForm error={errorMsg} onFormSubmit={handleAccountRegister} />
          <GrandText id="accountRegistration">
            {overrideText(
              `För att lättare kunna följa denna och andra beställningar kan du skapa ett lösenord för
          konto.`,
              overrides?.accountRegistration
            )}
          </GrandText>
        </VStack>
      </GrandSection>
    </OverridesContextProvider>
  )
}

// https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
const RegisterPageStdSuspended: FC<RegisterPageStdProps> = (props) => {
  const overrides = useOverridesContext<keyof RegisterPageStdOverrides>()

  return (
    <Suspense
      fallback={
        <OverridesContextProvider overrides={{ ...props.config.overrides }}>
          <GrandSection minH={800} sectionId="registrera">
            <VStack
              alignSelf="center"
              bg="background.surface"
              borderColor="primary"
              borderRadius="sm"
              borderWidth={1}
              divider={<StackDivider />}
              p={6}
              w={{
                base: 'full',
                md: 'lg'
              }}>
              <GrandHeading title={overrideText('Registrera', overrides?.registerAccount)} />
              <ChangePasswordForm onFormSubmit={() => void 0} />
              <GrandText id="accountRegistration">
                {overrideText(
                  `För att lättare kunna följa denna och andra beställningar kan du skapa ett lösenord för
            konto.`,
                  overrides?.accountRegistration
                )}
              </GrandText>
            </VStack>
          </GrandSection>
        </OverridesContextProvider>
      }>
      <RegisterPageStd {...props} />
    </Suspense>
  )
}

export default RegisterPageStdSuspended
